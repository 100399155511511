<template>
  <div :style="{'background-color': sfondo_3, 'color':testo_sfondo_3}">
    <!-- Sezione Mappa 100% -->
    <div class="d-md-block map-dettaglio-necrologio" id="dettaglio-necrologio-map">
      <l-map style="height: 300px; width:100%; position" :zoom="zoom" :center="getNormalizedGeolocation(geolocation)">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="getNormalizedGeolocation(geolocation)"></l-marker>
      </l-map>
    </div>
    <!-- Mappa su bonile con altezza ridotta -->
    <div class="map-dettaglio-necrologio-mobile" id="dettaglio-necrologio-map">
      <l-map style="height: 150px; width:100%; position" :zoom="zoom" :center="getNormalizedGeolocation(geolocation)">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="getNormalizedGeolocation(geolocation)"></l-marker>
      </l-map>
    </div>
    <!-- /Sezione Mappa 100% -->

    <!-- other-info -->
    <div class="container-80 other-info-necrologio">
      <div class="container-info-necrologio">
        <div class="photo-necrologio">
          <img
              class="img-defunto-necrologio-vega" :style="{'border':'5px solid' + testo_sfondo_3}"
              v-bind:src="getPhotoNecrologioFromCdn(dettaglio_necrologio)">
        </div>
        <div class="info-personali-necrologio">
          <div class="nome-eta">
            <span class="nome" :style="{'color':testo_sfondo_3}">{{ nome_defunto }}</span><br>
            <span class="age" :style="{'color':testo_sfondo_3}">{{
                calculateYears(dettaglio_necrologio.data_nascita, dettaglio_necrologio.data_morte)
              }} anni</span>
          </div>
          <div class="year-birth-necrologio">
            <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">Anno di nascita:</span><br>
            <span class="data-nascita-necrologio"
                  :style="{'color':testo_sfondo_3}">{{ dataDettaglioNecrologio(data_nascita) }}</span>
          </div>
          <div class="year-death-necrologio">
            <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">Anno della scomparsa:</span><br>
            <span class="data-morte-necrologio" :style="{'color':testo_sfondo_3}">{{
                dataDettaglioNecrologio(data_morte)
              }}</span>
          </div>
          <div class="cimitero-necrologio">
            <span :style="{'color':testo_sfondo_3, 'opacity': 0.4}">Cimitero</span><br>
            <span class="sepoltura" :style="{'color':testo_sfondo_3}">{{ cimitero }}</span>
          </div>
        </div>
      </div>
      <!--
      <div class="shop-necrologio">
        <img class="logo-services-necrologio" src="~@/assets/TemplateImg/img-necrologi/icone_necrologi-01.svg" alt="">
        <img class="logo-services-necrologio" src="~@/assets/TemplateImg/img-necrologi/icone_necrologi-03.svg" alt="">
        <img class="logo-services-necrologio" src="~@/assets/TemplateImg/img-necrologi/icone_necrologi-05.svg" alt="">
      </div>
      -->
    </div>
    <!-- /other-info -->
    <!-- Pensieri dal cuore-->
    <div v-for="item in pensieriCuore" :key="item.id">

      <div v-if="this.pensieriCuore !== undefined && this.pensieriCuore.length >0" class="pensieri-cuore container-80">
        <h3 :style="{'color':testo_sfondo_3}">Pensieri dal cuore</h3>
        <hr :style="{'color':testo_sfondo_3}">
        <img class="heart" :style="{'color':testo_sfondo_3}" src="~@/assets/TemplateImg/img-necrologi/cuore.svg">
      </div>
      <div class="lettera-necrologio">
        <p>
          {{ item.pensiero_dal_cuore }}
          {{ item.autore }}
        </p>
      </div>
    </div>

    <hr :style="{'color':testo_sfondo_3}" id="fine-lettera">
    <!-- /Pensieri dal cuore-->

    <!-- Numero dei manifesti del defunto -->
    <b-row class="container-80 num-manifesti-defunto">
      <b-col md="3" v-for="item in manifesti_necrologio" :key="item.id">

        <router-link :to="{name: 'DettaglioManifesto', params: {id: item.id}}">

          <img class="dettaglio-photo-manifesto-necrologio"
               v-bind:src="item.foto_manifesto"
               alt="">

        </router-link>

      </b-col>
    </b-row>
    <!-- Numero dei manifesti del defunto -->

    <!-- Passo le props al componente Footer -->
    <!-- Fine componente Footer -->
  </div>
</template>


<script>

import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import {mapGetters} from "vuex";
import {
  normalizeDataNascita,
  normalizeGeolocation,
  yearsCalculate,
  photoNecrologioFromCDN,
} from "@/utils/util";

export default {
  name: "DettaglioNecrologio",
  computed: {
    ...mapGetters([
      'texture',
      'sfondo_3',
      'testo_sfondo_3',
    ]),
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  data() {

    return {
      necrologioId: null,
      dettaglio_necrologio: [],
      pensieriCuore: [],
      nome_defunto: null,
      data_morte: null,
      data_nascita: null,
      cimitero: null,
      manifesti_necrologio: [],
      geolocation: [],
      // Dati Mappa
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFyb2NjbzEyIiwiYSI6ImNsazc1ejNyaDA3NzkzdHA2cmppNWN4MzUifQ.PGAGDXVwWq6njX4L3EUw7w',
      attribution: '',
      zoom: 17,
      // Fine dati mappa
    }
  },

  metaInfo() {
    return {
      title: 'Necrologio - ' + this.nome_defunto,
      meta: [
        {
          name: 'description',
          content: this.chi_siamo
        },
        {property: 'og:title', content: ' - Epiloge'},
        {property: 'og:site_name', content: 'Epiloge'},
        {
          property: 'og:description',
          content: 'Connect and follow on Epiloge - '
        },
        {property: 'og:type', content: ''},
        {property: 'og:url', content: ''},
        {property: 'og:image', content: ''}
      ]
    }
  },
  methods: {

    calculateYears(data_nascita, data_morte) {
      return yearsCalculate(data_nascita, data_morte)
    },

    dataDettaglioNecrologio(data_nascita) {
      return normalizeDataNascita(data_nascita)
    },

    getPhotoNecrologioFromCdn(item) {
      return photoNecrologioFromCDN(item)
    },

    getNormalizedGeolocation(geolocation) {
      return normalizeGeolocation(geolocation)
    },

    getManifestiFromIdNecrologio() {
      // eslint-disable-next-line no-undef
      axios
          .get(this.$API_MANIFESTI_FROM_ID_NECROLOGIO + this.necrologioId)
          .then((response) => {
            if (response.status === 200) {
              this.manifesti_necrologio = response.data.results;
            }
          }, (error) => {
            console.log(error);
          });
    },


    getPensieriDelCuore() {
      // eslint-disable-next-line no-undef
      axios
          .get(this.$API_PENSIERI_DEL_CUORE + "?necrologio_id=" + this.necrologioId)
          .then((response) => {
            console.log('PENSIERI DAL CUORE', response);
            if (response.status === 200) {
              this.pensieriCuore = response.data.results;
            }
          }, (error) => {
            console.log(error);
          });
    },

    getDettaglioNecrologio() {
      // eslint-disable-next-line no-undef
      axios
          .get(this.$API_DETTAGLIO_NECROLOGIO + this.necrologioId)
          .then((response) => {
            console.log('DATI RICEVUTI', response);
            if (response.status === 200) {
              this.dettaglio_necrologio = response.data;
              this.nome_defunto = this.dettaglio_necrologio.nome;
              this.data_morte = this.dettaglio_necrologio.data_morte;
              this.data_nascita = this.dettaglio_necrologio.data_nascita;
              this.cimitero = this.dettaglio_necrologio.cimitero;
              this.geolocation = this.dettaglio_necrologio.geolocation;
            }
          }, (error) => {
            console.log(error);
          });
    },


    handleScroll() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById("dettaglio-necrologio-map").style.marginTop = "0px";
      } else {
        document.getElementById("dettaglio-necrologio-map").style.marginTop = "80px";
      }
    },
  },

  created() {
    this.necrologioId = this.$router.history.current.params.id;
    this.getDettaglioNecrologio();
    this.getManifestiFromIdNecrologio()
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import '~@/assets/css/template_vega/dettaglio_necrologio.css';
</style>
