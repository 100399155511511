const CDN_URL = process.env.VUE_APP_CDN_URL;

export function iconaServizio(name, icone) {
    if (icone !== null && icone.length > 0) {
        for (const images of icone) {
            let img = images.img;
            if (images.servizio.nome === name &&
                images.template.id === parseFloat(localStorage.getItem('template'))) {
                return img;
            }
        }
    }
    return '';
}

export function normalizeDataNascita(data_nascita) {
    const date = new Date(data_nascita)
    return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
        date.getFullYear()
}

export function normalizeDataMorte(data_morte) {
    const date = new Date(data_morte)
    return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
        date.getFullYear()
}

export function normalizeGiornoOraMessa(giorno_ora_messa) {
    const date = new Date(giorno_ora_messa)
    return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
        date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}

export function normalizeDataRitoFunebre(data_rito_funebre) {
    const date = new Date(data_rito_funebre)
    return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
        date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}

export function yearsCalculate(data_nascita, data_morte) {
    if (data_nascita !== undefined && data_morte !== undefined) {
        data_nascita = new Date(data_nascita).getFullYear()
        data_morte = new Date(data_morte).getFullYear()
        return data_morte - data_nascita;
    } else {
        return 0;
    }
}

export function photoNecrologioFromCDN(item) {
    if (item.foto !== null) {
        return CDN_URL + item.foto.foto_home_necrologi_desktop
    } else {
        return item.foto_profilo;
    }
}

export function photoManifestoFromCDN(item) {
    if (item.foto !== null) {
        return CDN_URL + item.foto.foto_home_necrologi_desktop
    } else {
        return item.foto_manifesto;
    }
}

export function normalizeGeolocation(value) {
    let geolocationArray = value.split(",")
    const longitude = parseFloat(geolocationArray[0])
    const latitude = parseFloat(geolocationArray[1])
    return [longitude, latitude];
}

