<template>
  <div>
    <!-- Desktop -->
    <div class="three-background">
      <div class="first-bcg" :style="{ 'background-image': createCarouselBcg }">
        <div v-if="texture" class="texture-logo-agency">
          <img :src="texture" alt="">
        </div>
        <router-link to="/">
          <div class="logo-agency-mock2"
               :style="{ 'background-image': 'url(' + logo_azienda + '-/stretch/fill/-/format/auto/' + ')' }">
          </div>
        </router-link>
      </div>
      <div class="wrapper-second-bcg">
        <div class="second-bcg" :style="{ 'backgroundColor': sfondo_3 + '!important' }"></div>
        <div class="last-aside-bcg" :style="{ 'background-color': sfondo_2 }"></div>
      </div>
    </div>
    <!-- /Desktop -->

    <!-- Mobile -->
    <div class="three-background-mobile">
      <div class="first-bcg" :style="{ 'background-color': sfondo_1 }">
        <div v-if="texture" class="texture-logo-agency">
          <img :src="texture" alt="">
        </div>
        <router-link to="/">
          <div class="logo-agency-mock2"
               :style="{ 'background-image': 'url(' + logo_azienda + '-/stretch/fill/-/format/auto/' + ')' }">
          </div>
        </router-link>
      </div>
      <div class="wrapper-second-bcg">
        <div class="second-bcg" :style="{ 'backgroundColor': sfondo_3 + '!important' }"></div>
        <div class="last-aside-bcg" :style="{ 'background-color': sfondo_1 }"></div>
      </div>
    </div>
    <!-- /Mobile -->
  </div>
</template>

<script>

import {mapGetters} from "vuex";


export default {
  name: "LogoAgency",
  props: {
    secondBcg: String
  },

  computed: {

    createCarouselBcg() {
      return `linear-gradient(90deg, ${this.sfondo_1} 80%, ${this.sfondo_2} 20%)`;
    },

    ...mapGetters([
      'logo_azienda',
      'testo_sfondo_1',
      'testo_sfondo_2',
      'testo_sfondo_3',
      'sfondo_1',
      'sfondo_2',
      'sfondo_3',
    ]),

  },
}
</script>

<style scoped>
@import '~@/assets/css/template_altair/homepage.css';
</style>

