import { render, staticRenderFns } from "./DettaglioNecrologio.vue?vue&type=template&id=5392b6a2&scoped=true"
import script from "./DettaglioNecrologio.vue?vue&type=script&lang=js"
export * from "./DettaglioNecrologio.vue?vue&type=script&lang=js"
import style0 from "./DettaglioNecrologio.vue?vue&type=style&index=0&id=5392b6a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5392b6a2",
  null
  
)

export default component.exports